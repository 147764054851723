/* .slick-dots {
    top: 540px;
} */

.slick-thum {
    position: absolute !important;
    top: 510px;
}

.slic-dots {
    display: flex !important;
    gap: 20px;
    margin-left: 100px;
}

.slic-dots li {
    width: 90px !important;
}

.slick-thum img {
    height: unset !important;
    filter: grayscale(75%);
}

/* .slick-active img{
    border: 1px solid red;
} */

.slick-thum li.slick-active img{
    filter: grayscale(0);
}

@media only screen and (max-width: 768px) {
    .slick-thum {
        position: absolute !important;
        top: 510px;
    }

    .slic-dots li {
        width: 90px !important;
    }

    .slic-thum img {
        height: unset !important;
    }

    .slic-dots {
        top: 510px !important;
        display: flex !important;
        gap: 10px;
        margin-left: 25px;
    }
}