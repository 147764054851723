/* styles.css */

@media (max-width: 767px) {
    .home_page .new_collection_container .row:nth-child(odd) .col-md-4:nth-child(1) {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .home_page .new_collection_container .row:nth-child(even) .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
