.slick-dots {
    top: 270px;
}

.slick-dots li.slick-active button:before {
    color: white !important;
    font-size: 14px !important;
}

.slick-dots li button:before {
    color: black !important;
    font-size: 12px !important;
}

.slider .item .image {
    background-color: #f2eee778;
    width: 20rem;
    overflow: hidden;
}