:root {
  --text-color: white;
  --primary-color: black;
  --secondary-color: #f2eee7;
  --brightGrey-color: #777;
}

/************************************ Global *********************************/

a {
  text-decoration: none !important;
}

ul {
  list-style: none;
  padding: 0 !important;
}

::selection {
  background-color: var(--secondary-color);
}

input:focus,
textarea:focus {
  background-color: initial !important;
}

/* This styles the entire scrollbar (vertical and horizontal) */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the vertical scrollbar */
  height: 12px;
  /* Height of the horizontal scrollbar */
}

/* This styles the track (part the thumb slides within) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Light grey background for the track */
  border-radius: 10px;
  /* Makes the track corners rounded */
}

/* This styles the thumb (the part that you drag) */
::-webkit-scrollbar-thumb {
  background: #888;
  /* Dark grey thumb */
  border-radius: 10px;
  /* Rounded corners on the thumb */
}

/* Hover effect for the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Darker grey on hover */
}

/************************************ Popup Terms and Conditions *********************************/

.modal-footer button {
  color: var(--text-color);
  background-color: var(--secondary-color);
  border: none;
  outline: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 5px 7px 6px -2px;
}

.modal-footer button:hover {
  background-color: var(--secondary-color);
  color: initial;
}

.modal-footer button:focus {
  background-color: var(--secondary-color) !important;
  color: initial !important;
}

/************************************ Authentication *********************************/

.signup,
.signin {
  margin: 100px 0;
}

.card_auth {
  max-width: 500px;
  margin: auto;
  padding: 40px 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card_auth .row {
  margin-bottom: 30px;
}

.card_auth .row .col {
  text-align: left;
}

.card_auth .logo {
  max-width: 300px;
  margin: auto;
}

.card_auth .logo img {
  width: 100%;
}

.card_auth h2 {
  position: relative;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.card_auth h2::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.card_auth .form-label {
  color: var(--text-color);
}

.card_auth .form-control {
  background-color: #333;
  color: var(--text-color);
  border: none;
}

.card_auth .submit {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 5px 20px;
  font-size: 1.5rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.card_auth .submit:hover {
  background-color: #dbd4c6;
  color: var(--text-color);
}

.card_auth .check a {
  text-decoration: underline !important;
}

/************************************ NavBar *********************************/

.navigation {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.navbar {
  background-color: #222;
}

.navigation .dropdown a {
  color: var(--text-color);
}

.navigation .dropdown .dropdown-item {
  color: var(--primary-color);
}

.navigation .logo {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

.navigation .logo img {
  width: 100%;
  height: 100%;
}

.navbar-nav {
  --bs-nav-link-padding-y: 0 !important;
}

.navbar-nav>* {
  margin-right: 20px;
  color: var(--text-color);
}

.navbar-collapse {
  flex-grow: initial !important;
  text-align: right;
  align-items: unset;
  margin-top: 10px;
}

.navigation .drop_down .dropdown-menu {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
}

.navigation .drop_down .dropdown-menu a {
  color: var(--text-color);
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: white !important;
}

.navigation .actions {
  margin-left: 100px;
  display: flex;
}

.navigation .actions a {
  margin-right: 15px;
  color: var(--text-color);
}

.navigation .actions .cart_action {
  position: relative;
}

.navigation .actions .cart_action .total_cart_quantity {
  position: absolute;
  right: 5px;
  top: -15px;
  color: var(--text-color);
}

.navigation .navbar-toggler {
  background-color: var(--text-color);
  padding: 10px;
  vertical-align: top;
  box-shadow: 2px 1px 2px gray;
  margin: 4px 10px 4px 10px;
}

.navigation .navbar-toggler:focus {
  box-shadow: 2px 1px 2px gray;
}

.navigation .navbar-toggler:active {
  box-shadow: 0 0 0 var(--primary-color);
  margin: 6px 10px 2px 10px;
}

/************************ Admin ***************************/

.admin_home {
  margin-top: 40px;
}

.selections {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(500px, 100%), 1fr));
  gap: 20px;
}

.selections .selection {
  background-color: #333;
  color: var(--text-color);
  min-height: 200px;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.selections .selection h3 {
  background-color: #f8f9fa;
  color: var(--primary-color);
  padding: 5px 15px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.selections .selection .icon {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selections .selection .icon svg {
  font-size: 2.5rem;
}

/************************ Stock ***************************/

.stock h2 {
  text-align: center;
  font-weight: normal;
  position: relative;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.stock h2::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.stock .categories {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stock .categories .teams {
  display: flex;
  gap: 20px;
}

.stock .categories .teams button {
  background-color: var(--secondary-color);
  border: none;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.stock .categories select {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  padding: 5px 10px;
}

.stock .products h3 {
  text-align: center;
  text-transform: capitalize;
  font-size: 2.6rem;
  margin-bottom: 60px;
  position: relative;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.stock .products h3::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.stock .products p {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 60px;
  position: relative;
}

.stock .products p::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.stock .products .league {
  font-style: italic;
}

.stock .products .products_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(450px, 100%), 1fr));
  gap: 30px;
}

.stock .products .product {
  background-color: #77777732;
  color: var(--text-color);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.stock .products .product .image {
  height: 300px;
  overflow: hidden;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.stock .products .product .image img {
  width: 100%;
}

.stock .products .product .info {
  padding: 10px 20px;
}

.stock .products .product .info div {
  margin-bottom: 15px;
}

.stock .products .product .info div span {
  font-weight: 700;
}

.stock .products .product .info .delete_item {
  display: flex;
  justify-content: space-between;
}

.stock .products .product .info .delete_item button,
.stock .products .product .info .delete_item a.edit_product,
.stock .delete_all button {
  border: none;
  outline: none;
  background-color: rgb(159, 0, 0);
  color: var(--text-color);
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.stock .products .product .info .delete_item .edit_product {
  background-color: var(--text-color);
  color: var(--primary-color);
}

.stock .delete_all button {
  margin-top: 30px;
}

.stock .empty {
  color: var(--text-color);
}

/************************ Reviews Admin ***************************/

.reviews_admin .reviews .review {
  margin-bottom: 30px;
  border-bottom: 1px solid #77777732;
  border-top: 1px solid #77777732;
  padding: 20px 0;
  display: grid;
  color: var(--text-color);
  grid-template-columns: repeat(auto-fill, minmax(min(500px, 100%), 1fr));
}

.reviews_admin .reviews .review .info .stars {
  margin-top: 20px;
}

.reviews_admin h1 {
  font-weight: normal;
  position: relative;
  margin-bottom: 100px;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.reviews_admin h1::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.back_to_admin {
  color: var(--text-color);
  margin-bottom: 20px;
}

.back_to_admin svg {
  margin-right: 10px;
}

.back_to_admin a:hover svg {
  animation: animateArrow 0.9s ease-in-out infinite;
  -webkit-animation: animateArrow 0.9s ease-in-out infinite;
}

.reviews_admin .reviews .review .stars svg {
  color: #fdcc0d;
}

@keyframes animateArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  100% {
    transform: translateX(-10px);
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
  }
}

/************************ Home ***************************/

.home_page .carousel-item {
  position: relative;
  height: 800px;
}

.home_page .carousel-item::before {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.285);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.home_page .carousel-item img {
  height: 100%;
  object-fit: cover;
}

.home_page .carousel-caption {
  top: 50%;
}

.home_page .carousel-caption h2 {
  font-size: 3rem;
  margin-bottom: 15px;
}

.home_page .carousel-caption a {
  align-items: center;
  letter-spacing: 3px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  text-transform: uppercase;
}

.home_page .carousel-caption a:hover,
.home_page .carousel-caption a:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.home_page .carousel-caption a:hover {
  transform: translateY(-1px);
}

.home_page .carousel-caption a:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.home_page .about_section {
  margin-top: 100px;
  text-align: center;
}

.home_page .about_section h2 {
  text-transform: uppercase;
  font-weight: normal;
}

.home_page .about_section p {
  font-size: 1.2rem;
  width: 60%;
  margin: auto;
}

.home_page .new_collection {
  margin-top: 150px;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.home_page .new_collection p {
  font-size: 2rem;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 80px;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.home_page .new_collection p::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.home_page .new_collection .new_collection_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home_page .new_collection .new_collection_container .item {
  width: calc(100% / 3);
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding-right: 20px;
}

.home_page .new_collection .new_collection_container .lgsc-w50 {
  width: 50%;
}

.home_page .new_collection .new_collection_container .item a {
  width: 100%;
  position: relative;
  height: 100%;
  display: block;
  overflow: hidden;
}

.home_page .new_collection .new_collection_container .item a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: black;
  opacity: 0.3;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.home_page .new_collection .new_collection_container .item a:hover::after {
  opacity: 0.6;
}

.home_page .new_collection .new_collection_container .item a:hover img {
  scale: 1.1;
}

.home_page .new_collection .new_collection_container .item.half {
  width: 45%;
}

.home_page .new_collection .new_collection_container .item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.home_page .new_collection .new_collection_container .item .category {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-color);
  font-family: "Artttnorm";
  z-index: 1;
  font-size: 2.3rem;
}

.home_page .slider_container {
  margin-top: 80px;
}

.home_page .slider_container h2 {
  font-weight: normal;
  margin-bottom: 50px;
  text-align: center;
  position: relative;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.home_page .slider_container h2::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.home_page .slider_container .all_products a {
  margin-left: 65px;
  display: block;
  margin-bottom: 20px;
  color: var(--text-color);
  border: none;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  background-color: transparent;
  font-size: 1.4rem;
}

.slider {
  background-color: #333;
  padding: 30px 10px;
  gap: 40px;
  width: 90%;
  margin: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
  border-top: 1px solid #77777732;
  border-bottom: 1px solid #77777732;
}

/* .slider::-webkit-scrollbar {
  display: none;
}

.slider::-webkit-scrollbar-button {
  display: none;
  opacity: 0;
}

.slider::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
} */

.slider .item {
  color: var(--text-color);
  width: 20rem;

}

.slider .item.sale {
  position: relative;
}

.slider .item.sale::before {
  content: "SALE";
  position: absolute;
  top: -25px;
  left: 3px;
  background-color: transparent;
  border: 1px solid var(--text-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  z-index: 1;
}

.slider .item .image {
  background-color: #f2eee778;
  width: 20rem;
  height: 300px;
  overflow: hidden;
}

.slider .item .image img {
  width: 100%;
  height: 300px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}


.slider .item .info {
  padding: 20px 20px 10px 20px;
  background-color: #77777732;
  height: 150px;
}

.slider .item .info .name {
  text-align: center;
  text-transform: capitalize;
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 5px;
}

.slider .item .info .price {
  font-weight: 600;
  letter-spacing: 3px;
}

.slider .item .info .price_dashed {
  font-weight: 600;
  letter-spacing: 3px;
  text-decoration: line-through;
}

.slider .item .info span {
  font-weight: 600;
  letter-spacing: 3px;
}

.slider .item .info .add_cart svg {
  font-size: 1.2rem;
  cursor: pointer;
}

/************************ Products ***************************/

.products {
  margin-top: 80px;
}

.products h2 {
  text-align: center;
  font-size: 2.4rem;
  font-weight: normal;
  position: relative;
  margin-bottom: 80px;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.products h2::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.products .all_products a {
  margin-left: 65px;
  margin-bottom: 20px;
  display: inline-block;
  color: var(--text-color);
  border: none;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  background-color: transparent;
  font-size: 1.4rem;
}

/* .products .football_jerseys > div,
.products .studs_turfs > div {
  margin-bottom: 100px;
} */

.products .filter button,
.products_all .filter button {
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--text-color);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 3px 15px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.products .filter button:hover,
.products_all .filter button:hover {
  background-color: rgba(111, 111, 111, 0.8);
}

.products .filter button.active,
.products_all .filter button.active {
  background-color: var(--text-color);
  color: var(--primary-color);
}

/* .products .item_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(500px, 100%), 1fr));
  gap: 50px;
}

.products .item_container .item.sold_out {
  opacity: .6;
  cursor: initial;
  pointer-events: none;
  position: relative;
}

.products .item_container .item.sold_out::after {
  content: "SOLD OUT";
  position: absolute;
  font-size: .9rem;
  font-weight: 700;
  top: -25px;
  right: -35px;
  width: 90px;
  text-align: center;
  height: 90px;
  line-height: 90px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  rotate: 10deg;
}

.products .item_container .item {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}


.products .item_container .item .image {
  overflow: hidden;
  padding: 40px 60px;
  background-color: var(--secondary-color);
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
}

.products .item_container .item:not(.item.sold_out) .image:hover {
  opacity: .9;
}

.products .item_container .item img {
  width: 100%;
}

.products .item_container .item .info {
  display: flex;
  justify-content: space-between;
  background-color: #f2eee754;
  color: var(--text-color);
  padding: 15px 25px;
  text-transform: capitalize;
  font-style: oblique;
}

.products .item_container .item .info h3 {
  font-weight: normal;
}

.products .item_container .item .info p {
  font-size: 1.3rem;
  font-weight: bold;
} */

/************************ Products All ***************************/

.products_all {
  margin-top: 80px;
}

.products_all h2 {
  text-align: center;
  font-size: 2.4rem;
  font-weight: normal;
  position: relative;
  margin-bottom: 80px;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.products_all h2::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.products_all .items_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(450px, 100%), 1fr));
  gap: 20px;
}

.products_all .items_container .item {
  margin-bottom: 50px;
  color: var(--text-color);
  background-color: #333;
  padding: 20px 0;
}

.products .item_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(500px, 100%), 1fr));
  gap: 50px;
}

.products .item_container .item.sold_out {
  opacity: 0.6;
  cursor: initial;
  pointer-events: none;
  position: relative;
}

.products .item_container .item.sold_out::after {
  content: "SOLD OUT";
  position: absolute;
  font-size: 0.9rem;
  font-weight: 700;
  top: -25px;
  right: -35px;
  width: 90px;
  text-align: center;
  height: 90px;
  line-height: 90px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  rotate: 10deg;
}

.products_all .items_container .item .image {
  margin: auto;
  width: 400px;
  height: 400px;
}

.products_all .items_container .item .image img {
  width: 100%;
  height: 100%;
}

.products_all .items_container .item .info {
  text-align: center;
  margin-top: 20px;
}

.products_all .items_container .item .info .name {
  font-size: 1.6rem;
  font-weight: normal;
  text-transform: uppercase;
}

.products_all .items_container .item .info .price,
.products_all .items_container .item .info .price_dashed,
.products_all .items_container .item .info span {
  font-size: 1.4rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 30px;
}

.products_all .items_container .item .info .price_dashed {
  margin: 10px 0;
  text-decoration: line-through;
}

.products_all .items_container .item .info .price::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.products_all .items_container .item .info .sizes {
  margin-bottom: 40px;
}

.products_all .items_container .item .info .sizes button {
  background-color: transparent;
  border: none;
  padding: 5px 15px;
}

.products_all .items_container .item .info .sizes button.active {
  background-color: var(--secondary-color);
  border: 1px solid var(--text-color);
}

.products_all .items_container .item .info .sizes button:not(:last-child) {
  margin-right: 15px;
}

.products_all .items_container .item .info .add_to_cart {
  background-color: transparent;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 1rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.products_all .items_container .item .info .add_to_cart:hover {
  background-color: var(--secondary-color);
}

/************************ Product Details ***************************/

.product_details {
  margin-top: 80px;
}

.product_details .product_container {
  display: flex;
}

.product_details .product_container>div {
  width: calc(90% / 2);
}

.product_details .product_container .image {
  height: 600px;
  overflow: visible;
}

.product_details .product_container .image img {
  max-width: 100%;
  height: 500px;
}

.product_details .product_container .infos .title {
  font-size: 2.6rem;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: var(--text-color);
}

.product_details .product_container .infos .price {
  font-size: 1.8rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 70px;
  color: var(--text-color);
}

.product_details .product_container .infos .price::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.product_details .product_container .infos .sizes {
  margin-bottom: 40px;
  color: var(--text-color);
}

.product_details .product_container .infos .sizes button {
  background-color: transparent;
  border: none;
  padding: 5px 15px;
  color: var(--text-color);
  position: relative;
}

.product_details .product_container .infos .sizes button.active {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.product_details .product_container .infos .sizes button.not_available {
  pointer-events: none;
}

.product_details .product_container .infos .sizes button.not_available::before {
  content: "";
  position: absolute;
  background-color: white;
  width: 100%;
  height: 2px;
  top: 50%;
  left: 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.product_details .product_container .infos .sizes button:not(:last-child) {
  margin-right: 15px;
}

.product_details .product_container .infos .add_to_cart {
  background-color: transparent;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 1.3rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  color: var(--text-color);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.product_details .product_container .infos .add_to_cart:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

/************************ Contact Us ***************************/

.contact_us {
  margin-top: 80px;
}

.contact_us h2 {
  font-weight: normal;
  text-align: center;
  position: relative;
  margin-bottom: 100px;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.contact_us h2::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.contact_us .form-label {
  color: var(--text-color);
}

.contact_us form {
  text-align: center;
  width: 50%;
  margin: auto;
}

.contact_us form>div {
  margin-bottom: 10px;
  text-align: left;
}

.contact_us form>div input,
.contact_us form>div textarea {
  padding: 5px 15px;
  border: 1px solid var(--secondary-color);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  outline: none;
  background-color: #7777771b;
}

.contact_us form>div input:focus,
.contact_us form>div textarea:focus {
  outline: 1px solid #d7c9b1;
}

.contact_us form .name_email input {
  width: 100%;
}

.contact_us form .phone input,
.contact_us form .message textarea {
  width: 100%;
}

.contact_us form .send button {
  background-color: var(--text-color);
  color: var(--primary-color);
  border: none;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  font-size: 1.2rem;
  padding: 10px 20px;
}

/************************ Cart ***************************/

.cart {
  margin-top: 80px;
  position: relative;
  font-family: Montserrat, sans-serif;
}

.cart .container {
  max-width: 50%;
  margin: auto;
}

.cart h2 {
  position: relative;
  font-weight: normal;
  text-align: center;
  margin-bottom: 100px;
  color: var(--text-color);
  font-family: "Artttnorm";
}

.cart h2::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

.cart .top_heading h4:last-child {
  margin-right: 20px;
  margin-left: 100px;
}

.cart .image p {
  margin-top: 40px;
  font-size: 2rem;
  text-transform: capitalize;
}

.cart .cart_items .cart_item {
  color: var(--text-color);
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #777;
}

.cart .cart_items .cart_item:nth-child(1) {
  border-top: 1px solid #777;
}

.cart .cart_items .cart_item .info .image {
  margin-right: 20px;
  width: 150px;
  height: 150px;
}

.cart .cart_items .cart_item .info .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cart .cart_items .cart_item .actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
}

.cart .cart_items .cart_item .content .remove_item button {
  color: var(--text-color);
  background-color: transparent;
  border: none;
  padding: 0;
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.cart .cart_items .cart_item .content h5 {
  font-style: italic;
}

.cart .cart_items .cart_item .quantity,
.cart .cart_items .cart_item .price {
  text-align: center;
}

.cart .cart_items .cart_item .quantity>div {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.cart .cart_items .cart_item .quantity .quantity_icons {
  justify-content: center;
}

.cart .cart_items .cart_item .quantity .quantity_icons>button:hover,
.cart .cart_items .cart_item .quantity .remove_item button:hover {
  background-color: var(--secondary-color);
}

.cart .cart_items .cart_item .quantity .minus,
.cart .cart_items .cart_item .quantity .plus {
  color: var(--text-color);
  border: none;
  padding: 0 10px;
  background-color: transparent;
}

.cart .cart_items .cart_item .quantity .remove_item {
  margin-top: 20px;
}

.cart .cart_items .cart_item .quantity .remove_item {
  width: fit-content;
  margin: auto;
  margin-top: 15px;
}

.cart .cart_items .cart_item .quantity .remove_item button {
  background-color: transparent;
  border: none;
  border: 1px solid var(--text-color);
  padding: 5px 15px;
  border-radius: 6px;
  transition: 0.3s;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.cart .cart_items .cart_item .quantity .quantity_selected {
  text-align: center;
  width: 60px;
  background-color: var(--secondary-color);
  font-size: 1.2rem;
}

.cart .cart_items .cart_item .price {
  font-size: 1.1rem;
}

.cart .cart_items .sub_total {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: var(--text-color);
}

.cart .cart_items .sub_total span {
  margin-left: 50px;
  /* font-weight: 800; */
}

.cart .cart_items .tax {
  color: var(--text-color);
}

.cart .cart_items .checkout_btn {
  text-align: right;
  margin-top: 30px;
}

.cart .cart_items .checkout_btn a {
  margin-bottom: 20px;
  display: inline-block;
}

.cart .cart_items .checkout_btn a,
.cart .cart_items .checkout_btn button {
  color: var(--text-color);
  background-color: black;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 10px 20px;
  font-size: 1.1rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.cart .cart_items .checkout_btn button {
  padding: 8px 20px;
}

.cart .cart_items .checkout_btn a:hover,
.cart .cart_items .checkout_btn button:hover {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

/************************ Add Products ***************************/

.add_products.edit {
  color: var(--text-color);
}

.add_products .btn {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  outline: none;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 5px 7px 6px -2px;
}

.add_products .code_info .code_list {
  width: 250px;
  height: 48px;
}

.add_products .btn:hover {
  background-color: var(--text-color);
  color: var(--primary-color);
  color: initial;
  scale: 1.02;
}

.add_products .btn:focus {
  background-color: var(--secondary-color) !important;
  color: initial !important;
}

.add_products .clear_fields_container {
  text-align: right;
  margin-bottom: 30px;
}

.add_products .clear_fields {
  appearance: none;
  background-color: var(--primary-color);
  border-radius: 4px;
  border-width: 0;
  color: var(--text-color);
  cursor: pointer;
  height: 48px;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
  -webkit-transition: box-shadow 0.15s, transform 0.15s;
  -moz-transition: box-shadow 0.15s, transform 0.15s;
  -ms-transition: box-shadow 0.15s, transform 0.15s;
  -o-transition: box-shadow 0.15s, transform 0.15s;
}

.add_products .clear_fields:focus {
  box-shadow: var(--secondary-color) 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    var(--secondary-color) 0 -3px 0 inset;
}

.add_products .clear_fields:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, var(--secondary-color) 0 -3px 0 inset;
  transform: translateY(-2px);
}

.add_products .clear_fields:active {
  box-shadow: var(--secondary-color) 0 3px 7px inset;
  transform: translateY(2px);
}

.add_products .position-relative:not(:nth-child(7)) {
  margin-bottom: 60px;
}

.add_products .position-relative:not(:nth-child(7)) .form-label {
  background-color: var(--secondary-color);
  border-radius: 6px;
  padding: 2px 10px;
  font-size: 0.9rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  position: absolute;
  top: -15px;
  left: 30px;
}

.add_products .position-relative:not(:nth-child(7)):hover .form-label {
  top: -40px;
  left: 0;
  font-size: 1.1rem;
  background-color: transparent;
  color: var(--text-color);
}

.add_products .form-control:not(.excluded-form-control):focus {
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 0.25rem var(--secondary-color);
  color: var(--text-color);
}

.add_products .form-control {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  background-color: #333;
  color: var(--text-color);
  border: none;
}

/************************ New Orders ***************************/

.orders h2 {
  position: relative;
  font-family: "Artttnorm";
}

.orders h2::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

/* .orders .types .quantity {
  margin-left: -40px;
} */

.orders .types .phone {
  margin-right: 80px;
}

.orders .types .area {
  margin-right: 80px;
}

.orders .new_orders_container {
  margin-top: 20px;
}

.orders .new_orders_container .new_item {
  margin-bottom: 80px;
  padding-bottom: 30px;
}

.orders table tbody {
  border-bottom: 1px solid #777;
}

.orders table tbody .image {
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
}

.orders table tbody .image img {
  width: 100%;
  height: 100%;
}

.orders .actions button {
  font-size: 0.8rem;
}

/************************ Checkout ***************************/

.checkout {
  overflow: hidden;
}

.checkout input,
.checkout select,
.checkout textarea {
  padding: 15px;
  background-color: #333;
  border: none;
  color: var(--text-color);
}

.checkout input:focus,
.checkout textarea:focus {
  color: var(--text-color);
}

.checkout input::placeholder,
.checkout textarea::placeholder {
  color: var(--text-color);
}

.checkout label {
  color: var(--text-color);
}

.checkout .containing {
  justify-content: space-between;
  z-index: 10;
}

.checkout form {
  width: 90%;
  margin: auto;
  padding-bottom: 50px;
}

.checkout .headlines p,
.checkout h2 {
  color: var(--text-color);
}

.checkout .headlines a {
  color: var(--brightGrey-color);
  text-decoration: underline !important;
}

.form-check-input[type="checkbox"] {
  padding: initial;
}

.checkout .shipping_price h2 {
  margin-bottom: 20px;
}

.checkout .shipping_price p {
  background-color: var(--secondary-color);
  padding: 20px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.checkout .buy_products button {
  background-color: var(--primary-color);
  border: none;
  color: var(--text-color);
}

.checkout .buy_products.sm button {
  display: none;
}

.checkout .floating_receipt {
  background-color: #77777732;
  width: 70%;
  margin: 0 auto;
}

.checkout .floating_receipt .content {
  color: var(--text-color);
  width: 50%;
  padding: 20px 25px 0 25px;
}

.checkout .floating_receipt .content .item .info .image {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 20px;
}

.checkout .floating_receipt .content .item .info {
  font-size: 0.9rem;
  justify-content: space-between;
}

.checkout .floating_receipt .content .item .info .image img {
  width: 100%;
}

.checkout .floating_receipt .content .item .info .title h5 {
  font-size: 1rem;
}

.checkout .floating_receipt .content .item .totals {
  margin-top: 10px;
  font-size: 0.9rem;
}

/* .checkout .floating_receipt .content .item .totals > div {
  display: flex;
  justify-content: space-between;
} */

.checkout .floating_receipt .content .item .totals .subtotal p {
  font-weight: bold;
}

.checkout .floating_receipt .content .item .totals .total {
  font-weight: bold;
}

.checkout .slider .item.sale::before {
  content: "SALE";
  position: absolute;
  top: -25px;
  left: 0;
  background-color: transparent;
  border: 1px solid var(--text-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  z-index: 1;
}

/************************ Policies ***************************/

.policy {
  margin-top: 100px;
}

.policy h2 {
  position: relative;
  margin: 0 auto 80px auto;
  border-bottom: 1px solid var(--text-color);
  width: fit-content;
  padding-bottom: 15px;
}

.policy h3 {
  position: relative;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--text-color);
  width: fit-content;
  padding-bottom: 15px;
}

.policy .content {
  font-size: 1.4rem;
  max-width: 60%;
  margin: auto;
}

.policy .content p {
  margin-bottom: 50px;
}

/************************ Footer ***************************/

footer {
  margin-top: 150px;
}

footer h2 {
  font-weight: normal;
  position: relative;
  margin-bottom: 80px;
  color: var(--text-color);
  font-family: "Artttnorm";
}

footer h2::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

footer .policies_socials {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(350px, 100%), 1fr));
  gap: 70px;
  text-align: center;
}

footer .policies_socials .moto h3 {
  color: var(--text-color);
}

footer .policies_socials .moto p {
  color: var(--brightGrey-color);
}

footer .policies_socials ul li {
  line-height: 36px;
}

footer .policies_socials ul li a {
  color: var(--brightGrey-color);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

footer .policies_socials ul li a:hover {
  color: var(--text-color);
}

footer .policies_socials .socials {
  display: flex;
  justify-content: center;
}

footer .policies_socials .socials a {
  margin-right: 20px;
  font-size: 1.6rem;
}

footer .copyright {
  margin-top: 60px;
  position: relative;
}

footer .copyright::before {
  content: "";
  position: absolute;
  top: -10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 1px;
  background-color: var(--text-color);
}

/************************ Media Query ***************************/
@media (max-width: 1220px) {}

@media (max-width: 992px) {
  :root {
    font-size: 14px;
  }

  .navbar-collapse,
  .navbar-nav {
    order: 1;
    text-align: left;
  }

  .navigation .logo {
    order: 2;
  }

  .navigation .actions {
    order: 3;
  }

  .navigation .actions {
    margin-left: 0;
  }

  .navbar-nav>* {
    margin-bottom: 10px;
  }

  .home_page .new_collection {
    width: 100%;
  }

  .home_page .new_collection p {
    margin-bottom: 40px;
  }

  .home_page .new_collection .new_collection_container .item {
    width: calc(95% / 2);
    height: 150px;
    padding-right: 0;
  }

  .home_page .new_collection .new_collection_container .smsc-w100 {
    width: 100%;
    height: 200px;
  }

  .product_details .product_container {
    flex-direction: column;
  }

  .product_details .product_container .infos {
    order: 2;
  }

  .product_details .product_container>div {
    width: 100%;
  }

  .product_details .product_container .image {
    margin-bottom: 40px;
  }

  .contact_us form {
    width: 100%;
  }

  /* Checkout */

  .checkout {
    padding: 0 40px;
  }

  .checkout .containing {
    flex-direction: column;
  }

  .checkout .containing form {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid #77777732;
  }

  .checkout .containing .floating_receipt {
    width: 100%;
    position: relative;
    margin-top: 60px;
    padding-top: 50px;
  }

  .checkout .containing .floating_receipt::before {
    content: "Order Summary";
    color: var(--text-color);
    position: absolute;
    font-size: calc(1.325rem + 0.9vw);
    top: -45px;
    left: 0;
  }

  .checkout .containing .floating_receipt .content {
    position: relative;
  }

  .checkout .buy_products.lg button {
    display: none;
  }

  .checkout .buy_products.sm button {
    display: block;
  }
}

@media (max-width: 767px) {
  :root {
    font-size: 12px;
  }

  /******************* Sign UP *********************/

  .stock .categories {
    flex-direction: column;
  }

  .stock .categories .teams {
    margin-bottom: 30px;
  }

  /***************** Add Products ******************/

  .add_products .code_info {
    flex-direction: column;
  }

  .add_products .code_info .code_list {
    margin-left: auto;
    margin-right: 15px;
  }

  /***************** Cart ******************/

  .cart h2 {
    margin-bottom: 50px;
  }

  .cart .container {
    max-width: 100% !important;
  }

  .cart .top_heading {
    display: none !important;
  }

  .cart .cart_items .cart_item {
    flex-direction: column;
  }

  .cart .cart_items .cart_item .info {
    /* margin-bottom: 30px; */
    width: 100%;
  }

  .cart .cart_items .cart_item .actions {
    justify-content: space-between;
    width: 90%;
  }

  .cart .cart_items .cart_item .info .image {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  /*********** Checkout ************/

  .checkout .contact {
    flex-direction: column;
  }

  .checkout .contact input {
    margin-bottom: 20px;
  }

  .checkout .floating_receipt .content {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .home_page .new_collection .new_collection_container .item:not(.smsc-w100) {
    height: 140px;
  }

  .slider .item .image {
    background-color: #f2eee778;
    width: 20rem;
    height: 230px;
    overflow: hidden;
  }

  .slick-dots {
    top: 210px;
  }
}